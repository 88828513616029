import "./src/styles/global.scss";
import "./src/styles/global.scss";
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";

const onRedirectCallback = (appState) => {
	// Use Gatsby's navigate method to replace the url
	navigate(appState?.returnTo || "/", { replace: true });
};

export const wrapRootElement = ({ element }) => {
	return (
		<Auth0Provider
			domain={"richmond-events.uk.auth0.com"}
			clientId={"mchOL7BmXkJq5nHIb4qqDJFtdQIFUE2G"}
			cacheLocation="localstorage"
			authorizationParams={{
				redirect_uri: "https://register.richmondevents.com/admin/home",
			}}
			onRedirectCallback={onRedirectCallback}
		>
			{element}
		</Auth0Provider>
	);
};
